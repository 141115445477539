import isEmpty from 'lodash-es/isEmpty';
import PropTypes from 'prop-types';
import React, { useContext } from 'react';

import GatsbyIpfFileContext from '../../../context/GatsbyIpfFileContext';
import IndexContext from '../../../context/IndexContext';
import MarketDataContext from '../../../context/MarketDataContext';
import SiteSettingsContext from '../../../context/SiteSettingsContext';
import { DATA_SOURCE_INDEX } from '../../../utils/constants';
import ContractSpecsBlock from '../ContractSpecsBlock';
import MarketComparisons from '../MarketComparisons';

const propTypes = {
  fields: PropTypes.array,
  subtitle: PropTypes.object,
  symbol: PropTypes.string,
  title: PropTypes.object,
  optionsTitle: PropTypes.object,
  tooltip: PropTypes.object,
  sliceType: PropTypes.string
};

const defaultProps = {
  fields: [],
  subtitle: {},
  symbol: '',
  title: {},
  optionsTitle: {},
  tooltip: {},
  sliceType: ''
};

function WidgetFeeds({
  fields,
  subtitle,
  symbol,
  title,
  optionsTitle,
  tooltip,
  sliceType
}) {
  const siteSettings = useContext(SiteSettingsContext);
  const productData = useContext(GatsbyIpfFileContext);
  const indices = useContext(IndexContext);
  const { summaryTradeData } = useContext(MarketDataContext);

  const dataSource =
    (siteSettings.products[symbol] &&
      siteSettings.products[symbol].contract_specs_data_source) ||
    DATA_SOURCE_INDEX;

  // Have to check for the existence of the symbol because futures-only symbols won't be on an IPF file day 1.
  const frontMonthSymbol =
    !isEmpty(productData) &&
    !isEmpty(productData[symbol]) &&
    productData[symbol].frontMonthSymbol;
  const finalSymbol =
    dataSource === DATA_SOURCE_INDEX ? indices[symbol] : frontMonthSymbol;

  return (
    <>
      {sliceType === 'market_comparisons' ? (
        <MarketComparisons
          data={summaryTradeData[finalSymbol]}
          fields={fields}
          subtitle={subtitle}
          title={title}
          tooltip={tooltip}
        />
      ) : (
        <ContractSpecsBlock
          data={summaryTradeData[finalSymbol]}
          fields={fields}
          title={title}
          optionsTitle={optionsTitle}
          tooltip={tooltip}
          sliceType={sliceType}
        />
      )}
    </>
  );
}

WidgetFeeds.propTypes = propTypes;
WidgetFeeds.defaultProps = defaultProps;

export default WidgetFeeds;
